import Swal from "sweetalert2";
import { GET_ADMIN_KPI, GET_ALL_SELLER, GET_ALL_USER, GET_ATTRIBUTE, GET_BANK_LIST, GET_CATEGORY, GET_COUNTRY, GET_COUPON, GET_NOTIFICATIONS, GET_ORDER, GET_ORDER_STATUS, GET_PACKAGE, GET_PRODUCTS, GET_REPORTED_POSTS, GET_ROLES, GET_ROLES_MODULE_LIST, GET_STATE, GET_CITY, GET_TIMESLOT, GET_WALLET_LIST, GET_WINNERS_LIST, SET_LOADING, USER_LOGIN_DETAILS, GET_SELLER_DROPDOWN, GET_DASHBOARD, GET_USERS_DROPDOWN, GET_SUB_CATEGORY_DROPDOWN, GET_DRIVERS_DROPDOWN, GET_WAREHOUSE_LOGIN, GET_WAREHOUSE } from "./type";
import axios from "axios";
import Constant from "../Constant";

var config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("loged_In_auth"),
    },
};
export const setLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: data,
  });
  if (data == true) {
    document.getElementById("preloader").children[0].style = "display: block"
    document.getElementById("preloader").style = "display: block; background: #6c757d7a"
  } else {
    document.getElementById("preloader").children[0].style = "display: none;"
    document.getElementById("preloader").style = "display: none;"
  }
};

export const loginUser = (data) => async (dispatch) => {
  let baseUrl =  data.role == 'admin' ? "/admin/login" : "/user/login";
  if (data.role == 'admin') {
    try {
      // admin login
      await axios
        .post((Constant.getAPI()) + baseUrl, {
          email: data.email,
          password: data.password,
        })
        .then((res) => {
          dispatch({
            type: USER_LOGIN_DETAILS,
            payload: res.data,
          });
          Swal.fire({
            title: "Login Successfully",
            // icon: "",
            // imageUrl: "/assets/images/ZeedIcon.png",
            // imageHeight: 100,
            text: "",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
  
          if(res.data.data.userName=="Admin"){
            localStorage.setItem("role", 'admin');
          }else{
            localStorage.setItem("role", res.data.data.userName);
          }
  
          localStorage.setItem("loged_In_auth", res.data.auth);
          localStorage.setItem("ucs-userId", res.data.data.id);
          localStorage.setItem("ad_name", res.data.data.userName);
          localStorage.setItem("ad_email", res.data.data.email);
          localStorage.setItem("ucs-mobile", res.data.data.contactNo);
          localStorage.setItem("RoleId", res.data.data.RoleId);
          localStorage.setItem("loged_In", true);
          window.location.href = "/users";
          window.location.reload();
        })
        .catch((err) => {
          Swal.fire({
            title: err.message,
            icon: "",
            imageUrl: "./assets/images/favicon/android-chrome-192x192.png",
            imageHeight: 100,
            text: "",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        });
    } catch (err) {
      Swal.fire("Incorrect Credentials", "", "error");
    } 
  } else {
    // Vendor login
    try {
      await axios
        .post(Constant.getAPI() + baseUrl, {
          email: data.email,
          password: data.password,
          isVendor: 1,
        })
        .then((res) => {
          console.log(res);
          dispatch({
            type: USER_LOGIN_DETAILS,
            payload: res.data,
          });
  
          Swal.fire({
            title: "Login Successfully",
            icon: "",
            imageUrl: "./assets/images/favicon/android-chrome-192x192.png",
            imageHeight: 100,
            text: "",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
  
          console.log('Seller', res.data);
  
        
            localStorage.setItem("role", 'vendor');
          
  
          localStorage.setItem("loged_In_auth", res?.data?.auth);
          localStorage.setItem("vendorId", res?.data?.id);
          localStorage.setItem("firstName", res?.data?.firstName);
          localStorage.setItem("lastName", res?.data?.lastName);
          localStorage.setItem("email", res?.data?.email);  
          localStorage.setItem("mobileNumber", res?.data?.mobileNumber);
          localStorage.setItem("logo", res?.data?.image?.url);
          localStorage.setItem("loged_In", true);
          window.location.href = "/vendor-dashboard";
          // window.location.reload();
        })
        .catch((err) => {
          Swal.fire({
            title: 'Unauthorized User',
            icon: "",
            imageUrl: "./assets/images/favicon/android-chrome-192x192.png",
            imageHeight: 100,
            text: "",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        });
    } catch (err) {
      console.log(err);
      Swal.fire("Incorrect Credentials", "", "error");
    }
  }
};

// USer List
export const getallUsers = (data) => (dispatch) => {
  // dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/user/getAll",data,config)
    .then((res) => {
      dispatch({
        type: GET_ALL_USER,
        payload: res.data.data,
      });
      // dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// USer List Backend
export const getallUsersBackend = (data) => (dispatch) => {
  // dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/user/backend-getAll",data,config)
    .then((res) => {
      dispatch({
        type: GET_ALL_USER,
        payload: res.data.data,
      });
      // dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// GET SELLER
export const getSeller = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/admin/sellerGetAll", data,config)
    .then((res) => {
      dispatch({
        type: GET_ALL_SELLER,
        payload: res.data.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// GET SELLER BACKEND
export const getSellerBackend = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/seller/backend-getAll", data,config)
    .then((res) => {
      dispatch({
        type: GET_ALL_SELLER,
        payload: res.data.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
  
// update seller      
//Update seller By Admin
export const sellerUpdate = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
  .post(Constant.getAPI() + `/seller/updateByAdmin`, data, config)
  .then(res => {
    Swal.fire({
      title: "Updated",
      // icon: "",
      // imageUrl: "/assets/images/ZeedIcon.png",
      // imageHeight: 100,
      text: "Seller details has been Updated",
      type: "success",
      confirmButtonColor: "#3ab1f7",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    });
    dispatch(setLoading(false))
    window.location.href = "/sellers";
  })
  .catch((err) => {
    Swal.fire({
      title: 'Error',
      // icon: "",
      imageUrl: "/assets/images/ZeedIcon.png",
      imageHeight: 100,
      text: err?.response?.data?.error,
      type: "error",
      confirmButtonColor: "#3ab1f7",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    });
  });
}

// GET ROLES
export const getRole = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/role/list", data,config)
    .then((res) => {
      dispatch({
        type: GET_ROLES,
        payload: res.data.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const addRole = (data) => (dispatch) => {
  dispatch(setLoading(true));
    axios
      .post(Constant.getAPI() + "/role/add", data, config)
      .then((res) => {
        Swal.fire({
          title: "Role Added",
          type: "success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        dispatch(getRole());
        window.location.href = "/roles";
      })
      .catch((err) =>{
        Swal.fire({
          title: (err.response.data.error.errors[0].message || err.response.data.message||err.response.data  ),
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
};

export const editRole = (data) => (dispatch) => {
  dispatch(setLoading(true));
    axios
      .post(Constant.getAPI() + "/role/edit", data, config)
      .then((res) => {
        Swal.fire({
          title: "Role Updated",
          type: "success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        dispatch(getRole());
        window.location.href = "/roles";
      })
      .catch((err) =>{
        Swal.fire({
          // title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
};

// DELETE ROLES

//DELETE CATEGORY
export const deleteRoles = (data) => (dispatch) => {
  //console.log(data)
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/role/delete", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            // imageUrl: "./assets/images/test.png",
            // imageHeight: 100,
            type: "warning",
          });
          window.location.reload();
          dispatch(getRole());
        })
        .catch((err) => console.log(err));
    }
  });
};

// Wallet
export const getWalletList = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/admin/listSendMoneyToBank", data,config)
    .then((res) => {
      dispatch({
        type: GET_WALLET_LIST,
        payload: res.data.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const getBankList = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/bank/myfatoorahBankList ",data, config)
    .then((res) => {
      
      dispatch({
        type: GET_BANK_LIST,
        payload: res.data.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// CATEGORY LIST
export const getCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/category/get",data, config)
    .then((res) => {
      
      dispatch({
        type: GET_CATEGORY,
        payload: res.data.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// CATEGORY LIST BACKEND
export const getCategoryBackend = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/category/backend-getAll",data, config)
    .then((res) => {
      
      dispatch({
        type: GET_CATEGORY,
        payload: res.data.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// ADD CATEGORY
export const addCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/category/add", data, config)
    .then((res) => {
      // console.log(res);
      Swal.fire({
        title: "Category added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "/category";
      dispatch(getCategory());
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//update category
export const updateCategory = (data) => (dispatch) => {
  // console.log(data);
  axios

    .post(Constant.getAPI() + `/category/update`, data, config)
    .then((res) => {
      // console.log(res.data);
      Swal.fire({
        title: "Category Updated",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCategory());
      window.location.href = "/category";
    })
    .catch((err) => console.log(err));
};

//DELETE CATEGORY
export const deleteCategory = (data) => (dispatch) => {
  //console.log(data)
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/category/delete", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          window.location.reload();
          dispatch(getCategory());
        })
        .catch((err) => console.log(err));
    }
  });
};

// SUB CATEGORY LIST
export const getSubCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/subCategory/getAll", data, config)
    .then((res) => {
      dispatch({
        type: GET_SUB_CATEGORY_DROPDOWN,
        payload: res.data.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// ADD SUB CATEGORY
export const addSubCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/subCategory/add", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Sub Category added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "/sub-category";
      dispatch(getSubCategory());
    })
    .catch((err) => {
      Swal.fire({
        title:
          err?.response?.data?.error?.errors?.[0]?.message ||
          err?.response?.data?.message,
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//update category
export const updateSubCategory = (data) => (dispatch) => {
  console.log(data);
  axios

    .post(Constant.getAPI() + `/subCategory/edit`, data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "Category Updated",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getSubCategory());
      window.location.href = "/sub-category";
    })
    .catch((err) => console.log(err));
};

//DELETE CATEGORY
export const deleteSubCategory = (data) => (dispatch) => {
  //console.log(data)
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/subCategory/delete", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          window.location.reload();
          dispatch(getCategory());
        })
        .catch((err) => console.log(err));
    }
  });
};

// ADD Driver
export const addDriver = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/delivery/driver/add", data, config)
    .then((res) => {
      // console.log(res);
   dispatch(setLoading(false));
      Swal.fire({
        title: "Driver created successfully",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getDriverList());
      window.location.href = "/driver";
    })
    .catch((err) => {
      Swal.fire({
        title:
          err?.response?.data?.error?.errors?.[0]?.message ||
          err?.response?.data?.message,
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//update Driver
export const updateDriver = (data) => (dispatch) => {
  axios
    .post(Constant.getAPI() + `/delivery/driver/edit`, data, config)
    .then((res) => {
      // console.log(res.data);
      dispatch(setLoading(false));
      Swal.fire({
        title: "driver Updated",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getDriverList());
      window.location.href = "/driver";
    })
    .catch((err) => console.log(err));
};

export const deleteDriver = (data) => (dispatch) => {
  //console.log(data)
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/delivery/driver/delete", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          dispatch(setLoading(false));
          // window.location.reload();
          dispatch(getDriverList());
        })
        .catch((err) => console.log(err));
    }
  });
};

// driver Status
export const updateDriverStatus = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + `/delivery/driver/status`, data, config)
    .then((res) => {
      Swal.fire({
        title: "Status Updated",
        type: "success",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getDriverList());
      window.location.href = "/driver";
    })
    .catch((err) => {
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message || err?.response?.data),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

// GEt TIME SLOT
export const getTimeSlot = (data) => (dispatch) => {
  dispatch(setLoading(true));
    
  axios
  .post(Constant.getAPI() + "/config/backend-getAll-timeSlot", data,config)
  .then((res) => {
  dispatch({
    type: GET_TIMESLOT,
    payload: res.data.data,
  });
  // console.log(res);
  dispatch(setLoading(false));
})
.catch((err) => console.log(err));
};

// EDIT TIME SLOT
export const editTimeSlot = (data) => (dispatch) => {
  // dispatch(setLoading(true));
    axios
      .post(Constant.getAPI() + "/config/editTimeSlots", data, config)
      .then((res) => {
        if(res?.data?.status)
        {Swal.fire({
          title: "TimeSlot Updated",
          type: "success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        })
        window.location.href = "/time-slot";}
      })
      .catch((err) =>{
        Swal.fire({
          // title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
};

// DELETE TIMESLOT

export const deleteTimeSlot = (data) => (dispatch) => {
  //console.log(data)

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    imageUrl: "./assets/images/test.png",
    imageHeight: 100,
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + `/config/deleteTimeSlots`,data, config)
        .then((res) => {
          Swal.fire({
            title: "Time Slot Deleted",
            text: "You will not be able to recover this !",
            type: "warning",
            // imageUrl: "./assets/images/test.png",
            // imageHeight: 100,
            // showCancelButton: true,
            // confirmButtonText: "Yes, delete it!",
            // cancelButtonText: "No, keep it",
          });
          window.location.href = "/time-slot";
        })
        .catch((err) => console.log(err));
    }
  });
};


//get order
export const getOrder = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/order/getAll", data,config)
    .then((res) => {
      dispatch({
        type: GET_ORDER,
        payload: res.data.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// change order status
export const changeDeliveryStatus = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/order/changeDeliveryStatus", data, config)
    .then((res) => {
      dispatch({
        type: GET_ORDER,
        payload: res.data.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//get ADMIN KPI
export const getKPIAdmin = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/admin/dashboard-kpi", data,config)
    .then((res) => {
      dispatch({
        type: GET_ADMIN_KPI,
        payload: res?.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// order Status Liat
export const getOrderStatus= (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/orderStatus/get",  config,data,)
    .then((res) => {
    
      dispatch({
        type: GET_ORDER_STATUS,
        payload: res.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//get Package
export const getPackage = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/package/get", data,config)
    .then((res) => {
      dispatch({
        type: GET_PACKAGE,
        payload: res.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// ADD Package
export const addPackage = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/package/add", data, config)
    .then((res) => {
      // console.log(res);
      Swal.fire({
        title: "Package added",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getPackage());
      window.location.href = "/package";
    })
    .catch((err) =>{
    dispatch(setLoading(false));
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

// UPDATE PACKAGE
export const updatePackage = (data) => (dispatch) => {
  // console.log(data);
  axios

    .post(Constant.getAPI() + `/package/edit`, data, config)
    .then((res) => {
      // console.log(res.data);
      Swal.fire({
        title: "Package Updated",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCategory());
      window.location.href = "/package";
    })
    .catch((err) => console.log(err));
};

// GET Coupon
export const getCoupon = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/coupon/get", data,config)
    .then((res) => {
      dispatch({
        type:  GET_COUPON,
        payload: res?.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//ADD COUPON
export const addCoupon = (data) => (dispatch) => {
  // console.log(config);
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + `/coupon/add`, data, config)
    .then((res) => {
      // console.log(res);
      if(res.data.success){
        Swal.fire({
          title: "Coupon added",
          icon: "",
          imageUrl: "./assets/images/test.png",
          imageHeight: 100,
          text: "",
          type: "success",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        dispatch(setLoading(false));
        window.location.href = "/coupon";
      }else if(res.data.success===false){
        Swal.fire({
          title: "Error",
          icon: "error",
          text:  res?.data?.message,
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) =>{
      // console.log("ERR", err.response.data);
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//UPDATE COUPON
export const updateCoupon= (data) => (dispatch) => {
  // console.log(data);
  axios
    .post(Constant.getAPI() + "/coupon/edit", data, config)
    .then((res) => {
      // console.log(res.data);
      Swal.fire({
        title: "Coupon Updated",
        type: "success",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      // dispatch(getGovernorate());
      window.location.href = "/coupon";
    })
    .catch((err) => console.log(err));
};

export const updateCouponStatus = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + `/coupon/status`, data, config)
    .then((res) => {
      Swal.fire({
        title: "Coupon Updated",
        type: "success",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getRole());
    })
    .catch((err) => {
      Swal.fire({
        title:
          err?.response?.data?.error?.errors?.[0]?.message ||
          err?.response?.data?.message ||
          err?.response?.data,
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

export const updateCouponDisplay = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + `/coupon/display`, data, config)
    .then((res) => {
      Swal.fire({
        title: "Coupon Updated",
        type: "success",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getRole());
    })
    .catch((err) => {
      Swal.fire({
        title:
          err?.response?.data?.error?.errors?.[0]?.message ||
          err?.response?.data?.message ||
          err?.response?.data,
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

// REPORT POSTS LIST
export const getReportedPOST = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/post/reportList", data,config)
    .then((res) => {
      dispatch({
        type: GET_REPORTED_POSTS,
        payload: res?.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// COUNTRY LIST
export const getCountry = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .get(Constant.getAPI() + "/country/list", data,config)
    .then((res) => {
      dispatch({
        type: GET_COUNTRY,
        payload: res?.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//ADD COUNTRY
export const addCountry = (data) => (dispatch) => {
  // console.log(config);
  axios
    .post(Constant.getAPI() + `/country/add`, data.payload, config)
    .then((res) => {
      // console.log(res);
      Swal.fire({
        title: "Country added",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCountry());
      window.location.href = "#/country";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//UPDATE COUNTRY
export const updateCountry = (data) => (dispatch) => {
  // console.log(data);
  axios

    .patch(Constant.getAPI() + `/country/edit/${data.CountryId}`, data, config)
    .then((res) => {
      // console.log(res.data);
      Swal.fire({
        title: "Country Updated",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCountry());
      window.location.href = "/country";
    })
    .catch((err) => console.log(err));
};

// STATE LIST
export const getState = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/state/list",  config,data,)
    .then((res) => {
    
      dispatch({
        type: GET_STATE,
        payload: res.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// GET PRODUCT
export const getProduct = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/admin/getallpost", data,config)
    .then((res) => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// GET PRODUCT BACKEND
export const getProductBackend = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/admin/backend-getallpost", data,config)
    .then((res) => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const deleteProduct = (data) => (dispatch) => {
  //console.log(data)
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/admin/delete-product", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          dispatch(setLoading(false));
          // window.location.reload();
          dispatch(getProduct());
        })
        .catch((err) => console.log(err));
    }
  });
};

// WIINERS LIST
export const getWinners = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/admin/getDetailsForAuctions", data,config)
    .then((res) => {
      dispatch({
        type: GET_WINNERS_LIST,
        payload: res.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// GET ATTRIBUTE
export const getAttribute = (data) => (dispatch) => {
  dispatch(setLoading(true));
    axios
      .get(Constant.getAPI() + "/attribute/get", config)
      .then((res) => {
        dispatch({
          type: GET_ATTRIBUTE,
          payload: res.data?.data,
        });
      })
      .catch((err) =>{
        Swal.fire({
          title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
};

// ADD ATREBUTE
export const addAttribute = (data) => (dispatch) => {
  dispatch(setLoading(true));
    axios
      .post(Constant.getAPI() + "/attribute/add", data, config)
      .then((res) => {
        Swal.fire({
          title: "Attribute added",
          type: "success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        dispatch(getAttribute());
        window.location.href = "/attribute-All";
      })
      .catch((err) =>{
        Swal.fire({
          title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
};

//DELETE ATTRIBUTE
export const deleteAttribute = (data) => (dispatch) => {
  //console.log(data)
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/attribute/deleteattribute", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          window.location.reload();
          dispatch(getAttribute());
        })
        .catch((err) => console.log(err));
    }
  });
};

// GET  NOTIFICATION
export const getNotifications = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/notification/getAll", data,config)
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// SEND NOTIFICATION
export const SendNotification = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/notification/add", data,config)
    .then((res) => {
      Swal.fire({
        title: "Notification Sent",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "/push-notification";
      dispatch(getNotifications());
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};


// GET DASHBOARD
export const getDashboard = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/dashboard/list", data,config)
    .then((res) => {
      dispatch({
        type: GET_DASHBOARD,
        payload: res.data.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// Roles Module Listing
export const getRoleModules = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/module/list", data,config)
    .then((res) => {
      dispatch({
        type: GET_ROLES_MODULE_LIST,
        payload: res.data.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};


// CITY LIST
export const getCity = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/city/list",  config,data,)
    .then((res) => {
    
      dispatch({
        type: GET_CITY,
        payload: res.data?.data,
      });
      // console.log(5);
      // console.log(res);
      // console.log(5);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// GET SELLER DROPDOWN
export const getSellerDropdown = () => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(Constant.getAPI() + "/admin/get-users-dropdown",config)
    .then((res) => {
      dispatch({
        type: GET_SELLER_DROPDOWN,
        payload: res.data.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};


// GET CATEGORY DROPDOWN
export const getCategoryDropdown = () => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(Constant.getAPI() + "/admin/get-category-dropdown", config)
    .then((res) => {
      dispatch({
        type: GET_CATEGORY,
        payload: res.data.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//ADD STATE
export const addState = (data) => (dispatch) => {
  axios
    .post(Constant.getAPI() + `/state/add`, data, config)
    .then((res) => {
      // console.log(res);
      Swal.fire({
        title: "State added",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getState());
      window.location.href = "/state";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//UPDATE STATE
export const updateState = (data) => (dispatch) => {
  // console.log(data);
  axios

    .patch(Constant.getAPI() + `/state/edit/${data.StateId}`, data, config)
    .then((res) => {
      // console.log(res.data);
      Swal.fire({
        title: "State Updated",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getState());
      window.location.href = "/state";
    })
    .catch((err) => console.log(err));
};

const path = window.location.pathname;
const tri = path.slice(0, 14);

// ADD PRODUCT
export const addProduct = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/product/add", data, config)
    .then((res) => {
      // console.log(res);
      Swal.fire({
        title: "Product added",
        // icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = `${tri == "/product-Fixed" ? "/product-Fixed" : "/product-Fixed"}`;
      dispatch(getProduct());
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        imageUrl: "/assets/images/ZeedIcon.png",
        imageHeight: 100,
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//UPDATE PRODUCT
export const updateProduct = (data) => (dispatch) => {
  // console.log(data);
  axios
    .patch(Constant.getAPI() + `/product/edit`, data, config)
    .then((res) => {
      // console.log(res.data);
      Swal.fire({
        title: "Product Updated",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getProduct());
      window.location.href =`${tri == "/product-Fixed" ? "/product-Fixed" : "/product-Fixed"}`;
    })
    .catch((err) => {
      console.error("Error updating product:", err);
      // Handle error (e.g., display error message to user)
    });
};

// GET USER DROPDOWN
export const getUserDropdown = () => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(Constant.getAPI() + "/admin/get-users-dropdown", config)
    .then((res) => {
      dispatch({
        type: GET_USERS_DROPDOWN,
        payload: res.data.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// PRODUCT STATUS
export const updateProductStatus = (data) => (dispatch) => {
  dispatch(setLoading(true));
    axios
      .post(Constant.getAPI() + `/product/status`, data, config)
      .then((res) => {
        Swal.fire({
          title: "Role Updated",
          type: "success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        dispatch(getRole());
        window.location.href = "#/product-Fixed";
        dispatch(setLoading(false));
      })
      .catch((err) =>{
        Swal.fire({
          title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  };

// GET SUBCATEGORY DROPDOWN
export const getSubCategoryDropdown = (categoryId,userId=0) => (dispatch) => {
  dispatch(setLoading(true));
  const config = {
      headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('ucs-token')
      }
  };
  let url 
  // = categoryId 
  //   ? `${Constant.getAPI()}/admin/get-sub-category-dropdown?categoryId=${categoryId}`
  //   : `${Constant.getAPI()}/admin/get-sub-category-dropdown`;
  if (categoryId && userId == 0) {
    url = `${Constant.getAPI()}/admin/get-sub-category-dropdown?categoryId=${categoryId}`
  } else if (categoryId && userId) {
    url = `${Constant.getAPI()}/admin/get-sub-category-dropdown?categoryId=${categoryId}&userId=${userId}`
  } else {
    url = `${Constant.getAPI()}/admin/get-sub-category-dropdown`;
  }
  axios
      .get(url, config)
      .then((res) => {
          dispatch({
              type: GET_SUB_CATEGORY_DROPDOWN,
              payload: res.data.data,
          });
          dispatch(setLoading(false));
      })
      .catch((err) => {
          // console.error(err);
          dispatch(setLoading(false));
      });
};

// driver list
export const getDriverList = (data) => (dispatch) => {
  axios
    .post(Constant.getAPI() + "/delivery/driver/list", data, config)
    .then((res) => {
      dispatch({
        type: GET_DRIVERS_DROPDOWN,
        payload: res.data.data,
      });

    })
    .catch((err) => console.log(err));
};

export const updateUserStatus = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + `/user/status`, data, config)
    .then((res) => {
      // Swal.fire({
      //   title: "User status Updated",
      //   type: "success",
      //   imageHeight: 100,
      //   confirmButtonColor: "#3ab1f7",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Ok",
      // });
      dispatch(getRole());
    })
    .catch((err) => {
      Swal.fire({
        title:
          err?.response?.data?.error?.errors?.[0]?.message ||
          err?.response?.data?.message ||
          err?.response?.data,
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

export const updateUserVerified = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + `/user/verified`, data, config)
    .then((res) => {
      Swal.fire({
        title: "User verified Updated",
        type: "success",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getRole());
    })
    .catch((err) => {
      Swal.fire({
        title:
          err?.response?.data?.error?.errors?.[0]?.message ||
          err?.response?.data?.message ||
          err?.response?.data,
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

export const updateAdminStatus = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + `/role/status`, data, config)
    .then((res) => {
      Swal.fire({
        title: "Admin status Updated",
        type: "success",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getRole());
    })
    .catch((err) => {
      Swal.fire({
        title:
          err?.response?.data?.error?.errors?.[0]?.message ||
          err?.response?.data?.message ||
          err?.response?.data,
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};


// UPDATE USER 
export const updateUser = (data) => (dispatch) => {
  // console.log(data);
  axios

    .post(Constant.getAPI() + `/user/backend-user-update/${data.userId}`, data, config)
    .then((res) => {
      // console.log(res.data);
      Swal.fire({
        title: "User Updated",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        // imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getState());
      window.location.href = "/users";
    })
    .catch((err) => console.log(err));
}; 


// GET Warehouse List
export const getWarehouseList = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/warehouse/backend-getAll-warehouseAdmin",  config,data,)
    .then((res) => {
    
      dispatch({
        type: GET_WAREHOUSE,
        payload: res.data?.data,
      });
      // console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};



//DELETE WAREHOUSE ADMIN 
export const deleteWarehouse = (data) => (dispatch) => {
  //console.log(data)
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/warehouse/delete", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          window.location.reload();
          dispatch(getWarehouseList());
        })
        .catch((err) => console.log(err));
    }
  });
};



// ADD WAREHOUSE ADMIN 
export const addWarehouse = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/warehouse/add", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Warehouse Admin added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getSubCategory());
      window.location.href = `${tri == "/warehouse" ? "/warehouse" : ""}`;
    })
    .catch((err) => {
      Swal.fire({
        title:
          err?.response?.data?.error?.errors?.[0]?.message ||
          err?.response?.data?.message,
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//update Warehouse 
export const updateWarehouse = (data) => (dispatch) => {
  console.log(data);
  axios

    .post(Constant.getAPI() + `/warehouse/edit`, data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "Warehouse admin Updated",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getSubCategory());
      window.location.href = `${tri == "/warehouse" ? "/warehouse" : ""}`;
    })
    .catch((err) => console.log(err));
};

export const updateProfile = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/admin/update-profile", data, config)
    .then((res) => {
      Swal.fire({
        title: "Profile Updated",
        type: "success",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "/profile-update";
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};